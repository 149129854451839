import { getAPIHeaderOptions } from "../../utils/apiUtils";
import { IGetEventLogsRequestData, IGetEventLogsResponseData } from "../../types";
import { queriesApi } from "./queries.reducer";

const eventLogs = queriesApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET VEHICLE EVENT LOGS
		getEventLogs: builder.query<IGetEventLogsResponseData, IGetEventLogsRequestData>({
			query: (requestData) => ({
				url: "/analytics/numberplatevent",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			}),
			keepUnusedDataFor: 0
		})
	})
});

export const { useGetEventLogsQuery } = eventLogs;
